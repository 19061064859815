import { useMemo, useState } from 'react';

import { getResources } from './data/resource';
import { initShoppingCart } from "./util/initShoppingCart";
import { ProductContext, ShoppingCartContext } from "./util/useContext";

import AppRoutes from "./component/AppRoutes";
import { IPriceList } from "./type/IPriceList";
import { IProduct } from "./type/IProduct";
import { initProductMap } from "./util/initProductMap";
import { IProductMap } from "./type/IProductMap";
import { IProductHashtagMap } from "./type/IProductHashtagMap";
import { initProductHashtagMap } from "./util/initProductHashtagMap";
import { initHighlightProducts } from "./util/initHighlightProduts";
import { getPriceList } from './api/getPricelist';

function App() {
  const [shoppingCartString, setShoppingCart] = useState(initShoppingCart());
  const [priceList, setPriceList] = useState<IPriceList | undefined>({});
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [productMap, setProductMap] = useState<IProductMap>({});
  const [productHashtagMap, setProductHashtagMap] = useState<IProductHashtagMap>({});
  const [highlightProducts, setHighlightProducts] = useState<IProduct[]>([]);
  
  useMemo(async () => {
    const res = await getPriceList();
    setPriceList(res.data);
    const productMap = initProductMap(res.data);
    setProductMap(productMap);
    const newAll = Object.values(productMap)
    setAllProducts(newAll);
    setProductHashtagMap(initProductHashtagMap(newAll));
    setHighlightProducts(initHighlightProducts(res.data));
    return res.data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const allResources = useMemo(() => getResources(), []);

  return (
      <ShoppingCartContext.Provider
        value={{
          shoppingCartString,
          setShoppingCart,
        }}
      >
        <ProductContext.Provider
          value={{
            priceList,
            productMap,
          }}
        >
        <AppRoutes 
          allProducts={allProducts} 
          allResources={allResources} 
          productMap={productMap} 
          productHashtagMap={productHashtagMap}
          highlightProducts={highlightProducts}
        />
        </ProductContext.Provider>
      </ShoppingCartContext.Provider>
  );
}

export default App;
