import { IProduct } from "../type/IProduct";
import { IProductHashtagMap } from "../type/IProductHashtagMap";

export const initProductHashtagMap = (allProducts: IProduct[]): IProductHashtagMap => {
  return allProducts.reduce((prev, p) => {
    if (p.metadata.hashtags) {
      p.metadata.hashtags.forEach((hashtag) => {
        if (prev[hashtag] === undefined) prev[hashtag] = {};
        prev[hashtag][p.id] = p;
      });
    }
    return prev;
  }, {} as IProductHashtagMap);
}