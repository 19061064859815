import { productArray } from "../data/product/productMap";
import { IPriceList } from "../type/IPriceList";
import { IProductBase } from "../type/IProductData";
import { IProductMap } from "../type/IProductMap";

export const initProductMap = (priceList?: IPriceList) => {
  if (!priceList) return productArray.reduce((prev, p: IProductBase) => {
    prev[p.id] = { 
      ...p, 
      price: {
        price: "",
        cardPrice: "",
        pid: p.id,
      },
    };
    return prev;
  }, {} as IProductMap);
  
  return productArray.reduce((prev, p: IProductBase) => {
    prev[p.id] = { 
      ...p, 
      price: priceList[p.id] 
    };
    return prev;
  }, {} as IProductMap);
}