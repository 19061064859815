import { IResource } from "../../type/IResource";

import { why500 } from "./why500";
import { nextGen500 } from "./nextGen500";
import { monitorController } from "./monitorControllers";

const resources = [monitorController, nextGen500, why500];

export const getResources = (): IResource[] => resources;
export const getResource = (sid?: string): IResource | undefined => {
  if (!sid) return undefined;

  const resource = resources.filter((r) => r.id === sid);

  if (!resource) return undefined;

  return resource[0];
};