import ReactGA from "react-ga4";
import React, { Suspense, useEffect } from 'react';

import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { PATHS } from '../type/EnumPaths';
import { IResource } from "../type/IResource";
import { IProduct } from "../type/IProduct";
import Logo from "./Logo";
import { IProductMap } from "../type/IProductMap";
import { IProductHashtagMap } from "../type/IProductHashtagMap";

const FAQ = React.lazy(() => import("../Page/FAQ"));
const Home = React.lazy(() => import("../Page/Home"));
const About = React.lazy(() => import("../Page/About"));
const Brand = React.lazy(() => import("./Brand"));
const NotFound = React.lazy(() => import("../Page/NotFound"));
const Brands = React.lazy(() => import("./Brands"));
const Product = React.lazy(() => import("./Product"));
const Results = React.lazy(() => import("./Results"));
// const Checkout = React.lazy(() => import("./Checkout"));
const Resource = React.lazy(() => import("./Resource"));
const BrandLayout = React.lazy(() => import("../layout/BrandLayout"));
const MasterLayout = React.lazy(() => import('../layout/MasterLayout'));
const ProductLayout = React.lazy(() => import("../layout/ProductLayout"));
const CheckoutQuote = React.lazy(() => import("./CheckoutQuote"));
const CheckoutLayout = React.lazy(() => import("../layout/CheckoutLayout"));
const ResourceLayout = React.lazy(() => import('../layout/ResourceLayout'));
const CheckoutSummary = React.lazy(() => import("../Page/CheckoutSummary"));

interface AppRoutesProps {
  allProducts: IProduct[];
  allResources: IResource[];
  productMap: IProductMap;
  productHashtagMap: IProductHashtagMap;
  highlightProducts: IProduct[];
}

const AppRoutes = (props: AppRoutesProps) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  return (
    <Suspense fallback={<Logo />}>
      <Routes>
        <Route path={PATHS.INDEX} element={<MasterLayout />} >
          <Route index element={<Home highlighted={props.highlightProducts}/>} />

          <Route path={PATHS.BRANDS} element={<BrandLayout />}>
            <Route index element={<Brands />} />
            <Route path={`:id`} element={<Brand allProducts={props.allProducts}/>} />
            <Route path={PATHS.WILD_CARD} element={<Navigate to={'/brands'} replace={true} />} />
          </Route>

          <Route path={PATHS.PRODUCTS} element={<ProductLayout />}>
            <Route index element={<Results title={"Products"} allData={props.allProducts} />} />
            <Route path={':id'} element={<Product productMap={props.productMap} productHashtagMap={props.productHashtagMap} />} />
            <Route path={PATHS.WILD_CARD} element={<Navigate to={`/${PATHS.PRODUCTS}`} replace={true} />} />
          </Route>

          <Route path={PATHS.RESOURCES} element={<ResourceLayout />}>
            <Route index element={<Results title={"Resources"} allData={props.allResources} />} />
            <Route path={':id'} element={<Resource />} />
            <Route path={PATHS.WILD_CARD} element={<Navigate to={`/${PATHS.RESOURCES}`} replace={true} />} />
          </Route>

          <Route path={PATHS.CHECKOUT} element={<CheckoutSummary />} />

          <Route path={PATHS.FAQ} element={<FAQ />} />

          <Route path={PATHS.ABOUT} element={<About />} />

          <Route path={PATHS.WILD_CARD} element={<NotFound />} />
        </Route>

        <Route path={PATHS.CHECKOUT} element={<CheckoutLayout />}>
          <Route path={PATHS.QUOTE} element={<CheckoutQuote />} />

          <Route path={PATHS.WILD_CARD} element={<Navigate to={`/${PATHS.CHECKOUT}`} replace={true} />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default AppRoutes;