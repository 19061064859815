import { highlighted } from "../data/product/productMap"
import { IPriceList } from "../type/IPriceList"
import { IProduct } from "../type/IProduct"
import { IProductBase } from "../type/IProductData"

export const initHighlightProducts = (priceList?: IPriceList): IProduct[] => {
  if (!priceList) return highlighted.map((p: IProductBase) => ({
    ...p,
    price: {
      price: "",
      cardPrice: "",
      pid: p.id,
    },
  }));

  return highlighted.map((p: IProductBase) => ({
    ...p,
    price: priceList[p.id],
  }));
}