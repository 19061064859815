// import { bettermakerEQ542 } from "./bettermakerEQ542";
import { sixpack } from "./radial/radialSixpack";
import { radialWorkhorse } from "./radial/radialWorkhorse";
import { radialWorkhorseWMixer } from "./radial/radialWorkhorseWMixer";
// import { waTB12 } from "./warmAudio/WATB12-500";
import { wa12MKII } from "./warmAudio/WA12MIKII-500";
// import { cranborne500R8 } from "./cranborne500R8";
// import { shadowhillsDualVandergraph } from "./shadowhillsDualVandergraph";
import { ssl500sixchannel } from "./ssl/ssl500sixchannel";
import { sslGComp } from "./ssl/sslGComp";
import { germ500MKII } from "./chandlerLimited/germ500MKII";
import { TG2500 } from "./chandlerLimited/TG2500";
// import { Atlas } from "./prismSound/Atlas";
// import { Lyra2 } from "./prismSound/Lyra2";
// import { catapult } from "./radial/catapult";
// import { catapultMiniRX } from "./radial/catapultMiniRX";
import { rhea } from "./wesaudio/rhea";
import { dione } from "./wesaudio/dione";
import { prometheus } from "./wesaudio/prometheus";
import { hyperion } from "./wesaudio/hyperion";
import { calypso } from "./wesaudio/calypso";
import { mimas } from "./wesaudio/mimas";
import { titan } from "./wesaudio/titan";
import { phoebe } from "./wesaudio/phoebe";
import { ngbuscomp } from "./wesaudio/ngbuscomp";
import { ngleveler } from "./wesaudio/ngleveler";
import { dioneStarlight } from "./wesaudio/dioneStarlight";
import { beta76 } from "./wesaudio/beta76";
import { timbre } from "./wesaudio/timbre";
import { supercarrierII } from "./wesaudio/supercarrierII";
import { odd } from "./purpleAudio/5E1Odd";
import { action } from "./purpleAudio/5C1Action";
import { tav } from "./purpleAudio/5E2Tav";
import { lilpeqrM } from "./purpleAudio/5E3LilpeqrM";
import { mc77 } from "./purpleAudio/mc77";
import { biz } from "./purpleAudio/5M1Biz";
import { pants } from "./purpleAudio/5M2Pants";
import { cansII } from "./purpleAudio/5H1CansII";
import { moiyn } from "./purpleAudio/5S1Moiyn";
import { sweetTenRack } from "./purpleAudio/sweetTen";
import { ng76 } from "./wesaudio/ng76";
import { ng76StereoPair } from "./wesaudio/ng76Stereo";
import { mixingBundle } from "./wesaudio/mixingBundle";
import { recordingMixingBundle } from "./wesaudio/recordingMixingBundle";
import { distressorel8x } from "./empiricalLabs/distressorel8x";
import { distressorel8xPair } from "./empiricalLabs/distressorel8xPair";
import { distressorel8 } from "./empiricalLabs/distressorel8";
import { distressorel8Pair } from "./empiricalLabs/distressorel8Pair";
import { bigFreq } from "./empiricalLabs/freEQ";
import { arousor } from "./empiricalLabs/arousor";
import { mikeE } from "./empiricalLabs/el9MikeE";
import { el9MikeETwinPak } from "./empiricalLabs/el9MikeETwinPak";
import { lilFrEQ } from "./empiricalLabs/elqLilFrEq";
import { trakPak } from "./empiricalLabs/trakPak";
import { elx7Fatso } from "./empiricalLabs/elx7Fatso";
import { derrEsserH } from "./empiricalLabs/derrEsserH";
import { derrEsser } from "./empiricalLabs/derrEsser";
import { docDerr } from "./empiricalLabs/docDerr";
import { docDerrH } from "./empiricalLabs/docDerrH";
import { creme } from "./tegeler/creme";
import { eqp1 } from "./tegeler/eqp1";
import { cremeRC } from "./tegeler/cremeRC";
import { magnetismus2 } from "./tegeler/magnetismus2";
import { raumzeitmaschine } from "./tegeler/raumzeitmaschine";
import { schwerkraftmaschine } from "./tegeler/schwerkraftmaschine";
import { tubesummingmixer } from "./tegeler/tubesummingmixer";
import { varitubecompressor } from "./tegeler/varitubecompressor";
import { varituberecordingchannel } from "./tegeler/varituberecordingchannel";
import { vocalleveler500 } from "./tegeler/vocalleveler500";
import { mytheq500 } from "./tegeler/mytheq500";
import { hyperionEclipse } from "./wesaudio/hyperionEclipse";
import { prometheusEclipse } from "./wesaudio/prometheusEclipse";
import { vivid } from "./mother/vivid";
import { wai2950 } from "./mother/wai2950";
import { kamaya } from "./mother/kamaya";
import { pump } from "./empiricalLabs/pump";
import { mythvca500 } from "./tegeler/mythvca500";

export const productArray = [
  vivid,
  wai2950,
  kamaya,
  pump,
  mythvca500,
  phoebe,
  rhea,
  dione,
  dioneStarlight,
  prometheus,
  prometheusEclipse,
  hyperion,
  hyperionEclipse,
  mimas,
  ngbuscomp,
  ng76,
  ng76StereoPair,
  beta76,
  timbre,
  calypso,
  mixingBundle,
  recordingMixingBundle,
  cremeRC,
  creme,
  eqp1,
  schwerkraftmaschine,
  raumzeitmaschine,
  magnetismus2,
  vocalleveler500,
  mytheq500,
  biz,
  pants,
  odd,
  tav,
  lilpeqrM,
  action,
  mc77,
  cansII,
  moiyn,
  sweetTenRack,
  titan,
  distressorel8x,
  distressorel8xPair,
  distressorel8,
  distressorel8Pair,
  derrEsser,
  derrEsserH,
  docDerr,
  docDerrH,
  mikeE,
  el9MikeETwinPak,
  lilFrEQ,
  elx7Fatso,
  trakPak,
  arousor,
  bigFreq,
  ngleveler,
  supercarrierII,
  wa12MKII,
  varitubecompressor,
  varituberecordingchannel,
  tubesummingmixer,
  // waTB12,
  ssl500sixchannel,
  sslGComp,
  germ500MKII,
  TG2500,
  sixpack,
  radialWorkhorse,
  radialWorkhorseWMixer,
  // Atlas,
  // Lyra2,
  // catapult,
  // catapultMiniRX,
  // cranborne500R8, 
  // shadowhillsDualVandergraph, 
  // bettermakerEQ542,
];

export const highlighted = [
  kamaya,
  wai2950,
  vivid,
  pump,
  phoebe,
  dione,
  hyperion,
  ngbuscomp,
  elx7Fatso,
  distressorel8xPair,
  mythvca500,
  mc77,
  ng76,
  rhea,
  mimas,
  action,
  biz,
  pants,
  odd,
  derrEsser,
  docDerr,
  titan,
  sweetTenRack,
];
