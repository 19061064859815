import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

const Logo = () => {
  return (
    <Box sx={{ display: { xs: "none", lg: "block" }, position: "fixed", width: "400px", paddingTop: "50px", paddingLeft: "50px", textAlign: "left" }}>
      <Link
        color="inherit"
        style={{ textDecoration: 'none' }}
        to={"/"}
        component={RouterLink}
      >
        <img 
          src="/logo/3d-white-web.png" 
          alt="3d-white-logo"
          style={{ maxWidth: "200px"  }} 
        />
      </Link>
    </Box>
  )
}

export default Logo;